import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "@StarberryUtils"
import $ from "jquery"
import {
  inViewOptions1,
  inViewOptions,
  delayItems,
  containerVariants,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
} from "../../Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const Menudata = props => {
  useEffect(() => {
    $(".menu-bar .menu-nav ul .submenu-list").each(function (i, el) {
      // Remove white space
      var html = $(el).html().replace(/\s+/, "")
      // Check if element is :empty or length of html is zero
      if ($(el).is(":empty") || html.length == 0) {
        $(el).remove()
      } else {
        $(el).parent().addClass("has-submenu")
      }
    })

    setTimeout(function () {
      $(".menu-item.has-submenu .firstleveltrigger")
        .unbind()
        .on("click", function (e) {
          var checkactive = $(this).parent().attr("class")
          //console.log('parentttlcassss', $(this).parent().attr('class'))
          if (checkactive.indexOf("active") != -1) {
            $(".menu-item.has-submenu").removeClass("active")
            $(".submenu-list").stop(true, true).slideUp()
            $(".has-submenu .firstleveltrigger i").removeClass(
              "icon-minus-menu"
            )
            $(".has-submenu .firstleveltrigger i").addClass("icon-plus-menu")
            //console.log(checkactive + " found");
            //e.stopPropagation();
          } else {
            //console.log(checkactive + "not found");
            $(".menu-item.has-submenu").removeClass("active")
            $(".submenu-list").slideUp()
            $(".has-submenu .firstleveltrigger i").removeClass(
              "icon-minus-menu"
            )
            $(".has-submenu .firstleveltrigger i").addClass("icon-plus-menu")
            $(this).parent().toggleClass("active")
            $(this).parent().find(".submenu-list").stop(true, true).slideDown()
            $(this)
              .parent()
              .find(".firstleveltrigger i")
              .removeClass("icon-plus-menu")
            $(this)
              .parent()
              .find(".firstleveltrigger i")
              .addClass("icon-minus-menu")
            //e.stopPropagation();
          }
        })

      $(".menu-item.has-submenu .secondleveltrigger")
        .unbind()
        .on("click", function (e) {
          var checkactiveactivesubmenu = $(this).parent().attr("class")
          if (checkactiveactivesubmenu.indexOf("activesubmenu") != -1) {
            $(".menu-item.has-submenu .secondlevel-menu-item").removeClass(
              "activesubmenu"
            )
            $(".submenu-list-level2").stop(true, true).slideUp()
            $(".has-submenu .secondleveltrigger i").removeClass(
              "icon-minus-menu"
            )
            $(".has-submenu .secondleveltrigger i").addClass("icon-plus-menu")
            //console.log(checkactiveactivesubmenu + " found");
            //e.stopPropagation();
          } else {
            //console.log(checkactiveactivesubmenu + "not found");
            $(".menu-item.has-submenu .secondlevel-menu-item").removeClass(
              "activesubmenu"
            )
            $(".submenu-list-level2").slideUp()
            $(".has-submenu .secondleveltrigger i").removeClass(
              "icon-minus-menu"
            )
            $(".has-submenu .secondleveltrigger i").addClass("icon-plus-menu")
            $(this).parent().toggleClass("activesubmenu")
            $(this)
              .parent()
              .find(".submenu-list-level2")
              .stop(true, true)
              .slideDown()
            $(this)
              .parent()
              .find(".secondleveltrigger i")
              .removeClass("icon-plus-menu")
            $(this)
              .parent()
              .find(".secondleveltrigger i")
              .addClass("icon-minus-menu")
            //e.stopPropagation();
          }
        })
    }, 3000)
  })

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        servicesSubLink(publicationState: LIVE) {
          Main_menu {
            Main_Label
            Main_Url {
              id
              Label
              Primary_URL
            }
            HashTag {
              Hash_Url
              Label
              Sub_Url {
                id
                Label
                Primary_URL
              }
            }
          }
        }

        allMenus(sort: "Sorting:asc") {
          id
          Link_Type
          Label
          Primary_URL
          Secondary_URL
          Topmenu
          Template
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)
  var menu_data = data.glstrapi.allMenus
  var services_menu_data = data.glstrapi.servicesSubLink
  return (
    <React.Fragment>
      <InView {...inViewOptions1}>
        {({ ref, inView }) => (
          <motion.div
            // className="static-banner"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            {props.item.Topmenu === true && (
              <motion.li
                custom={1}
                variants={delayItems}
                className={
                  props.item.Topmenu && props.item.isActive
                    ? "menu-item menu-item-has-children active-menu"
                    : "menu-item menu-item-has-children"
                }
              >
                {props.item.Secondary_URL ? (
                  <Link className="firstleveltrigger">
                    {props.item.Topmenu === true && props.item.Label}
                  </Link>
                ) : props.item.Label === "Services" ? (
                  <Link className="firstleveltrigger">
                    {props.item.Topmenu === true && props.item.Label}
                  </Link>
                ): props.item.Label === "Marketing" ? (
                  <Link to={`/${props.item.Primary_URL}`}>
                    {props.item.Topmenu === true && props.item.Label}
                  </Link>
                )
                 : (
                  <Link className="firstleveltrigger">
                    {props.item.Topmenu === true && props.item.Label}
                  </Link>
                )}
                <div className="firstleveltrigger">
                  {menu_data.map((menuitem, key) => {
                    return (
                      <>
                        {menuitem.Main_Parent &&
                          menuitem.Sub_Parent === null &&
                          menuitem.Main_Parent.Label === props.item.Label && (
                            <i className="icon-plus-menu"></i>
                          )}
                        {props.item.Label === "Search" &&
                          menuitem.Label === "Search" && (
                            <i className="icon-plus-menu"></i>
                          )}
                      </>
                    )
                  })}
                </div>
                {props.item.Topmenu === true &&
                props.item.Label === "Services" ? (
                  <ul className="submenu-list">
                    {services_menu_data?.Main_menu?.map(submenu1 => {
                      return (
                        <>
                          {submenu1.Main_Url && submenu1.Main_Label && (
                            <motion.li
                              variants={delayItemsFromBottom}
                              className="menu-item secondlevel-menu-item"
                            >
                              <Link
                                to={`/${props.item.Primary_URL}/${submenu1?.Main_Url?.Primary_URL}`}
                              >
                                {submenu1.Main_Label}
                              </Link>
                              {/* {submenu1.Main_Url.Primary_URL === "reviews" && (
                                <>
                                  <div className="secondleveltrigger">
                                    <i className="icon-plus-menu"></i>
                                  </div>

                                  <ul className="submenu-list-level2">
                                    <li className="menu-item">
                                      <a
                                        onClick={() => {
                                          props.closeMenu()
                                        }}
                                        href="/area-guides"
                                      >
                                        Area Guides
                                      </a>
                                    </li>
                                  </ul>
                                </>
                              )} */}
                              {/* <div className="secondleveltrigger">
                                {submenu1?.HashTag?.map(submenu2 => {
                                  return (
                                    <>
                                      {submenu2?.Hash_Url &&
                                        submenu2?.Label && (
                                          <i className="icon-plus-menu"></i>
                                        )}
                                    </>
                                  )
                                })}
                              </div> */}

                              {/* <ul className="submenu-list-level2">
                                {submenu1?.HashTag?.map(submenu2 => {
                                  return (
                                    <>
                                      {submenu2?.Hash_Url && submenu2?.Label && (
                                        <li className="menu-item">
                                          <a
                                            onClick={() => {
                                              props.closeMenu()
                                            }}
                                            href={`/${props.item.Primary_URL}/${submenu1?.Main_Url?.Primary_URL}#section-${submenu2.Hash_Url}`}
                                          >
                                            {submenu2.Label}
                                          </a>
                                        </li>
                                      )}
                                    </>
                                  )
                                })}
                              </ul> */}
                            </motion.li>
                          )}
                        </>
                      )
                    })}
                  </ul>
                ) : (
                  <ul className="submenu-list">
                    {menu_data.map((submenu, index) => {
                      // console.log(submenu, "submenu")
                      return (
                        <>
                          {submenu.Main_Parent &&
                            submenu.Sub_Parent === null &&
                            submenu.Main_Parent.Label == props.item.Label && (
                              <>
                              {index === 24 &&
                              <motion.li
                              variants={delayItemsFromBottom}
                              className="menu-item secondlevel-menu-item"
                            >
                         <Link  to={`/${props.item.Primary_URL}`}>
                        {props.item.Label}
                        </Link>
                        </motion.li>
                         } 
                         {index === 17 &&
                              <motion.li
                              variants={delayItemsFromBottom}
                              className="menu-item secondlevel-menu-item"
                            >
                         <Link to={`/${props.item.Primary_URL}`}>
                        {props.item.Label}
                        </Link>
                        </motion.li>
                         } 
                              <motion.li
                                variants={delayItemsFromBottom}
                                className="menu-item secondlevel-menu-item"
                              >
                                {submenu.Secondary_URL ? (
                                  <Link to={`${submenu.Secondary_URL}`}>
                                    {submenu.Label}
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/${submenu.Main_Parent.Primary_URL}/${submenu.Primary_URL}`}
                                  >
                                    {submenu.Label}
                                  </Link>
                                )}
                                <div className="secondleveltrigger">
                                  {menu_data.map(submenu2 => {
                                    return (
                                      <>
                                        {submenu2.Main_Parent &&
                                          submenu2.Sub_Parent &&
                                          submenu2.Sub_Parent.Label ==
                                            submenu.Label && (
                                            <i className="icon-plus-menu"></i>
                                          )}
                                      </>
                                    )
                                  })}
                                </div>
                                <ul className="submenu-list-level2">
                                  {menu_data.map(submenu1 => {
                                    return (
                                      <>
                                        {submenu1.Main_Parent &&
                                          submenu1.Sub_Parent &&
                                          submenu1.Sub_Parent.Label ==
                                            submenu.Label && (
                                            <li className="menu-item">
                                              <Link
                                                to={`/${submenu1.Main_Parent.Primary_URL}/${submenu1.Sub_Parent.Primary_URL}/${submenu1.Primary_URL}`}
                                              >
                                                {submenu1.Label} 
                                              </Link>
                                            </li>
                                          )}
                                      </>
                                    )
                                  })}
                                </ul>
                                <ul className="submenu-list-level2">
                                  {menu_data.map(submenu1 => {
                                    return (
                                      <>
                                        {submenu1.Main_Parent &&
                                          submenu1.Sub_Parent &&
                                          submenu1.Sub_Parent.Label ==
                                            submenu.Label && (
                                            <li className="menu-item">
                                              <Link
                                                to={`/${submenu1.Main_Parent.Primary_URL}/${submenu1.Sub_Parent.Primary_URL}/${submenu1.Primary_URL}`}
                                              >
                                                {submenu1.Label}
                                              </Link>
                                            </li>
                                          )}
                                      </>
                                    )
                                  })}
                                </ul>
                              </motion.li>
                              </>
                            )}
                          {props.item.Label === "Search" &&
                            submenu.Label === "Search" && (
                              <>
                                <li className="menu-item secondlevel-menu-item">
                                  <Link to={`/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london/`}>
                                    Property for Sale
                                  </Link>
                                </li>
                                <li className="menu-item secondlevel-menu-item">
                                  <Link to={`/property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london/`}>
                                    Property to Rent
                                  </Link>
                                </li>
                              </>
                            )}
                        </>
                      )
                    })}
                  </ul>
                )}
              </motion.li>
            )}
          </motion.div>
        )}
      </InView>
    </React.Fragment>
  )
}

export default Menudata
