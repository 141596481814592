import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import { Row, Col } from "react-bootstrap"
import "animate.css/animate.css"
import $ from "jquery"
const PopularSearchDetails = props => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)

  var searchaction = ""
  var URL = ""
  var ptypeURL = ""
  var bedURL = ""
  var bedtypeText = ""
  var area = "London"
  var typeText = "Property"
  var searcharea = ""
  var searchareaText = ""
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  if (props.searchtype == "sales") {
    searchaction = "for sale"
    URL = "/property/for-sale/"
  }
  if (props.searchtype == "lettings") {
    searchaction = "to rent"
    URL = "/property/to-rent/"
  }
  if (props.property_building) {
    ptypeURL = "/type-" + props.property_building
    typeText = capitalizeFirstLetter(props.property_building.replace(/-/g, " "))
  }
  if (props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield + " Bedroom " + typeText
    bedURL = "/" + props.searchBedroomfield + "-and-more-bedrooms"
  }
  if (props.property_address) {
    area = props.property_address.address2
    searchareaText = props.property_address.address2.replace(/%20/g, " ")
  }
  return (
    <React.Fragment>
      <Row>
        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-Hackney${ptypeURL}`}
              >
                {typeText} {searchaction} in Hackney
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-Norfolk${ptypeURL}`}
              >
                {typeText} {searchaction} in Norfolk
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-Stoke-Newington${ptypeURL}`}
              >
                {typeText} {searchaction} in Stoke Newington
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-Thetford${ptypeURL}`}
              >
                {typeText} {searchaction} in Thetford
              </Link>
            </li>
          </ul>
        </Col>

        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-london/type-house-mid-terrace`}
              >
                Houses {searchaction} in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-london/type-flat`}
              >
                Flats {searchaction} in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-london/type-maisonette`}
              >
                Maisonette {searchaction} in {area}
              </Link>
            </li>
            <li>
              <Link className="footer_scroll_top" to={`${URL}in-london`}>
                Property {searchaction} in {area}
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-${area}/1-and-more-bedrooms`}
              >
                1 bedroom property {searchaction} in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-${area}/2-and-more-bedrooms`}
              >
                2 bedroom property {searchaction} in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-${area}/3-and-more-bedrooms`}
              >
                3 bedroom property {searchaction} in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${URL}in-${area}/4-and-more-bedrooms`}
              >
                4 bedroom property {searchaction} in {area}
              </Link>
            </li>
          </ul>
        </Col>

        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link className="footer_scroll_top" to={`/area-guides/`}>
                Areaguides in {area}
              </Link>
            </li>
            <li>
              <Link className="footer_scroll_top" to={`/contact`}>
                Estate agents in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`/property-services/landlords/`}
              >
                Property management in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`/property-services/sell/property-valuation`}
              >
                Property valuation
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PopularSearchDetails
