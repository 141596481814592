import React from 'react';
import {Breadcrumb} from "react-bootstrap";
import { Link } from "@StarberryUtils";
import { useLocation } from "@reach/router"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"

const BREADCRUMB=  gql`
   query NewsListing ($URL: String!){   
    allMenus(where:{Primary_URL:$URL}){
      Label
      Primary_URL
      Main_Parent {
        Label
        Primary_URL
      }
      Sub_Parent {
        Label
        Primary_URL
      }
    }
  }
`;

const Breadcrumbs = (props) => {
   //const location = useLocation();  
   //var currenturl = props.alias?props.alias:location.pathname.split("/").pop()   

   if (typeof window !== "undefined") {
    var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var currenturl = props.alias?props.alias:removeSlash.substr(removeSlash.lastIndexOf('/') + 1);  
  }


   var resultlabel = ""
   if(props.resultlabel) {
    resultlabel = props.resultlabel;
   }
   var propurl = ""
   var listname = ""
   if(props.property_type == "sales") {
    propurl="/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london";
    listname= "Property for sale";
   }
   if(props.property_type == "lettings") {
    propurl="/property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london";
    listname= "Property to rent";
   }
   const { loading, error, data } = useQuery(BREADCRUMB, {variables: { URL: currenturl }});
   if (loading) return <p>Loading ...</p>;
   //console.log('aaaaaaaa',data.allMenus.length)

 
    return (
        <Breadcrumb>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        {/* {data?.allMenus?.length == 0 && resultlabel == "" &&
        <Breadcrumb.Item><Link to="/contact">Stoke Newington Estate Agents</Link></Breadcrumb.Item>
        } */}
        {resultlabel &&
        <Breadcrumb.Item><Link to="/">{resultlabel}</Link></Breadcrumb.Item>
        }
        {props.property_type &&
        <Breadcrumb.Item><Link to={propurl}>{listname}</Link></Breadcrumb.Item>
        }
        {props.property_display_address &&
        <Breadcrumb.Item active>{props.property_display_address}</Breadcrumb.Item>
        }
        {data?.allMenus?.map((menu) => {
          return<>
            {menu.Main_Parent &&menu.Main_Parent.Label!=="Services"?
              <Breadcrumb.Item active>
                <Link to={`/${menu.Main_Parent.Primary_URL}`}>{menu.Main_Parent.Label}</Link>
              </Breadcrumb.Item>
              :
              menu.Main_Parent&&
              <Breadcrumb.Item active>
               {menu.Main_Parent.Label}
             </Breadcrumb.Item>
            }
            {menu.Main_Parent && menu.Sub_Parent &&
              <Breadcrumb.Item active>              
                <Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Sub_Parent.Primary_URL}`}>{menu.Sub_Parent.Label}</Link>
              </Breadcrumb.Item>
            }
            {props.baseurl &&
            <Breadcrumb.Item active>
              <Link to={`/${props.baseurl}/`}>{menu.Label}</Link>
            </Breadcrumb.Item>
            }
            {props.alias && props.catalias && props.page != 'news' &&
            <Breadcrumb.Item active>
              <Link to={`/${props.alias}/${props.catalias}/`}>{props.catlabel}</Link>
            </Breadcrumb.Item>
            }
            {props.alias && props.catalias && props.page == 'news' && props.baseurl &&
            <Breadcrumb.Item active>
              <Link to={`/${props.baseurl}/${props.catalias}/`}>{props.catlabel}</Link>
            </Breadcrumb.Item>
            }
            {props.menulabel ?
             <Breadcrumb.Item active>
               {props.menulabel}
             </Breadcrumb.Item>
             :
             <Breadcrumb.Item active>
               {menu.Label}
             </Breadcrumb.Item>
            }
            
            </>
        })}
        
      </Breadcrumb>
    )
}
export default Breadcrumbs;
