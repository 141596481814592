import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import { Row, Col } from "react-bootstrap"
import "animate.css/animate.css"
import $ from "jquery"
const PopularSearchDynamic = props => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)

  var searchaction = ""
  var URL = ""
  var ptypeURL = ""
  var bedURL = ""
  var bedtypeText = ""
  var area = "London"
  var typeText = "Property"
  var searcharea = ""
  var searchareaText = ""
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  if (props.searchtype == "sales") {
    searchaction = "for sale"
    URL = "/property/for-sale/"
  }
  if (props.searchtype == "rentals") {
    searchaction = "to rent"
    URL = "/property/to-rent/"
  }
  if (props.searchPtype) {
    ptypeURL = "/type-" + props.searchPtype
    typeText = capitalizeFirstLetter(props.searchPtype.replace(/-/g, " "))
  }
  if (props.searchBedroomfield) {
    bedtypeText =
      props.searchBedroomfield === "1"
        ? props.searchBedroomfield + " bedroom " + typeText
        : props.searchBedroomfield + " bedrooms " + typeText
    bedURL = "/" + props.searchBedroomfield + "-and-more-bedrooms"
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (
    props.Searcharea &&
    props.Searcharea.areas != "undefined" &&
    props.Searcharea.areas
  ) {
    searcharea = props.Searcharea.areas
    searchareaText = props.Searcharea.areas.replace(/%20/g, " ")
    area = props.Searcharea.areas
  }

  return (
    <React.Fragment>
      <Row>
        {props.searchBedroomfield ? (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Hackney${ptypeURL}${bedURL}`}
                >
                  {bedtypeText} {searchaction} in Hackney
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Norfolk${ptypeURL}${bedURL}`}
                >
                  {bedtypeText} {searchaction} in Norfolk
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Stoke-Newington${ptypeURL}${bedURL}`}
                >
                  {bedtypeText} {searchaction} in Stoke Newington
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Thetford${ptypeURL}${bedURL}`}
                >
                  {bedtypeText} {searchaction} in Thetford
                </Link>
              </li>
            </ul>
          </Col>
        ) : (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Hackney${ptypeURL}`}
                >
                  {typeText} {searchaction} in Hackney
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Norfolk${ptypeURL}`}
                >
                  {typeText} {searchaction} in Norfolk
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Stoke-Newington${ptypeURL}`}
                >
                  {typeText} {searchaction} in Stoke Newington
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-Thetford${ptypeURL}`}
                >
                  {typeText} {searchaction} in Thetford
                </Link>
              </li>
            </ul>
          </Col>
        )}
        {searcharea !==
        "stoke newington-and-hackney-and-north-london-and-east-london" ? (
          <Col md={6} lg={3}>
            <ul>
              {ptype && searchbed && (
                <li>
                  <Link
                    className="footer_scroll_top"
                    to={`${URL}in-${searcharea}`}
                  >
                    Property {searchaction} in {searchareaText}
                  </Link>
                </li>
              )}
              {ptype && searcharea && (
                <li>
                  <Link
                    className="footer_scroll_top"
                    to={`${URL}in-${searcharea}`}
                  >
                    Property {searchaction} in {searchareaText}
                  </Link>
                </li>
              )}
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${searcharea}/type-house-mid-terrace`}
                >
                  Houses {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${searcharea}/type-flat`}
                >
                  Flats {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${searcharea}/type-maisonette`}
                >
                  Maisonettes {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${searcharea}/type-bungalow`}
                >
                  Bungalows {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </Link>
              </li>
            </ul>
          </Col>
        ) : (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-hackney/type-house-mid-terrace`}
                >
                  Houses {searchaction} in Hackney
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-london/type-flat`}
                >
                  Flats {searchaction} in London
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-stoke-newington/type-maisonette`}
                >
                  Maisonettes {searchaction} in Stoke Newington
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-thetford/type-bungalow`}
                >
                  Bungalows {searchaction} in Thetford
                </Link>
              </li>
            </ul>
          </Col>
        )}
        {area !==
        "stoke newington-and-hackney-and-north-london-and-east-london" ? (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${area}/1-and-more-bedrooms`}
                >
                  1 bedroom property {searchaction} in{" "}
                  {capitalizeFirstLetter(area)}
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${area}/2-and-more-bedrooms`}
                >
                  2 bedrooms property {searchaction} in{" "}
                  {capitalizeFirstLetter(area)}
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${area}/3-and-more-bedrooms`}
                >
                  3 bedrooms property {searchaction} in{" "}
                  {capitalizeFirstLetter(area)}
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-${area}/4-and-more-bedrooms`}
                >
                  4 bedrooms property {searchaction} in{" "}
                  {capitalizeFirstLetter(area)}
                </Link>
              </li>
            </ul>
          </Col>
        ) : (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-hackney/1-and-more-bedrooms`}
                >
                  1 bedroom property {searchaction} in Hackney
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-london/2-and-more-bedrooms`}
                >
                  2 bedrooms property {searchaction} in London
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-stoke-newington/3-and-more-bedrooms`}
                >
                  3 bedrooms property {searchaction} in Stoke Newington
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`${URL}in-thetford/4-and-more-bedrooms`}
                >
                  4 bedrooms property {searchaction} in Thetford
                </Link>
              </li>
            </ul>
          </Col>
        )}

        {area !==
        "stoke newington-and-hackney-and-north-london-and-east-london" ? (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link className="footer_scroll_top" to={`/area-guides/`}>
                  {capitalizeFirstLetter(area)} area guides
                </Link>
              </li>
              <li>
                <Link className="footer_scroll_top" to={`/contact`}>
                  Estate agents in {capitalizeFirstLetter(area)}
                </Link>
              </li>
              <li>
              <Link
                  className="footer_scroll_top"
                  to={`/contact/london-estate-agents`}
                >
                  London estate agents
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`/property-services/sell/property-valuation`}
                >
                  Property valuation in {capitalizeFirstLetter(area)}
                </Link>
              </li>
            </ul>
          </Col>
        ) : (
          <Col md={6} lg={3}>
            <ul>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`/area-guides/london-area-guides/`}
                >
                  London area guides
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`/contact/london-estate-agents`}
                >
                  London estate agents
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`/contact/thetford-estate-agents`}
                >
                  Thetford estate agents
                </Link>
              </li>
              <li>
                <Link
                  className="footer_scroll_top"
                  to={`/property-services/sell/property-valuation`}
                >
                  Property valuation in London
                </Link>
              </li>
            </ul>
          </Col>
        )}
      </Row>
    </React.Fragment>
  )
}

export default PopularSearchDynamic
