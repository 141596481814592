import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import { Row, Col } from "react-bootstrap"
import "animate.css/animate.css"
import $ from "jquery"
const PopularSearchOffice = props => {
  //Get values from refine search

  var URL = ""

  var area = props.officename
  var areaUrl = props.officename === "Hackney" ? "hackney" : "thetford"

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  var saleUrl = "/property/for-sale/"
  var rentUrl = "/property/to-rent/"

  return (
    <React.Fragment>
      <Row>
        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${saleUrl}in-${areaUrl}/`}
              >
                Property for sale in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${saleUrl}in-${areaUrl}/type-house-mid-terrace/`}
              >
                Houses for sale in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${saleUrl}in-${areaUrl}/type-flat/`}
              >
                Flats for sale in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${saleUrl}in-${areaUrl}/type-bungalow/`}
              >
                Bungalows for sale in {area}
              </Link>
            </li>
          </ul>
        </Col>

        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${rentUrl}in-${areaUrl}`}
              >
                Property to rent in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${rentUrl}in-${areaUrl}/type-house-mid-terrace/`}
              >
                Houses to rent in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${rentUrl}in-${areaUrl}/type-flat/`}
              >
                Flats to rent in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${rentUrl}in-${areaUrl}/type-bungalow/`}
              >
                Bungalows to rent in {area}
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${saleUrl}in-${area}/type-maisonette/`}
              >
                Maisonettes for sale in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`${rentUrl}in-${area}/type-maisonette/`}
              >
                Maisonettes to rent in {area}
              </Link>
            </li>
            <li>
              <Link
                className="footer_scroll_top"
                to={`/property-services/sell/property-valuation/`}
              >
                Property valuation in Hackney
              </Link>
            </li>
            <li>
              <Link className="footer_scroll_top" to={`/marketing`}>
                Property marketing
              </Link>
            </li>
          </ul>
        </Col>

        <Col md={6} lg={3}>
          <ul>
            <li>
              <Link className="footer_scroll_top" to={`/purpose/our-people/`}>
                Meet the team
              </Link>
            </li>
            <li>
              <Link className="footer_scroll_top" to={`/purpose/`}>
                About location location
              </Link>
            </li>
            <li>
              <Link className="footer_scroll_top" to={`/purpose/reviews/`}>
                Testimonials
              </Link>
            </li>
            <li>
              <Link className="footer_scroll_top" to={`/purpose/work-with-us/`}>
                Estate agency jobs & vacancies
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PopularSearchOffice
