import Link from './link'; 


const PropertyCallTo = (officeCRMId)=>{
    if(officeCRMId === "19755bdf-cc89-4131-83c1-3065fdd44404"){
        return '01842 818688'
    }else if(officeCRMId === "bdb36880-baaa-477a-93a8-49e8b3998e5e"){
        return '0207 923 9222'
    }
    return;
}
export { Link, PropertyCallTo }
