import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import "../CookieConsent/CookieConsent.scss"

const CookieConsent = (props) => {
  const [CookieVisible, setCookieVisible] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window && window.cookieconsent && !CookieVisible) {
        setCookieVisible(true);
        window.cookieconsent.run({ "notice_banner_type": "interstitial", "consent_type": "express", "palette": "dark", "language": "en", "page_load_consent_levels": ["strictly-necessary"], "notice_banner_reject_button_hide": false, "preferences_center_close_button_hide": false, "page_refresh_confirmation_buttons": false, "website_name": "Location Location", "website_privacy_policy_url": "https://www.locationlocation.com/privacy-and-cookie-policy/" });
      }
    }, 300);
    return () => {
      clearInterval(intervalId);
    };
  }, [CookieVisible]);


  return (
    <React.Fragment>
      <Helmet>
        <script type="text/javascript" src="//www.freeprivacypolicy.com/public/cookie-consent/4.1.0/cookie-consent.js" charset="UTF-8"></script>
        <script type="text/plain" data-cookie-consent="tracking" async="" src="https://www.google-analytics.com/analytics.js"></script>
        <script type="text/plain" data-cookie-consent="tracking" async="" src="https://www.googletagmanager.com/gtag/js?id=AW-1001626836&l=dataLayer&cx=c"></script>
        <script type="text/plain" data-cookie-consent="tracking" src="https://www.googletagmanager.com/gtm.js?id=GTM-5VPLBGD"></script>
        <script type="text/plain" data-cookie-consent="tracking" >{`window.dataLayer = window.dataLayer || [];window.dataLayer.push({"platform":"gatsby"}); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', 'GTM-5VPLBGD');`}</script>
      </Helmet>
    </React.Fragment>
  )
}

export default CookieConsent
