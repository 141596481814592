import React from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown/with-html";
import { useStaticQuery, graphql } from "gatsby"


function PopularSearchStatic(props) {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        globalConfig {
            Popular_Search_Static_Common
            Popular_Search_Static_Sales
            Popular_Search_Static_Rentals
        }
      }
    }
  `)
    var footer_data = data.glstrapi.globalConfig;
    var popularSearchtype = props.popularSearch;
    return (
        <>
            {popularSearchtype=='Popular_Search_Static_Common' ?
            <ReactMarkdown source={footer_data.Popular_Search_Static_Common} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Sales' ?
            <ReactMarkdown source={footer_data.Popular_Search_Static_Sales} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Rentals' ?
            <ReactMarkdown source={footer_data.Popular_Search_Static_Rentals} escapeHtml={false}/>
            : ''
            }
        </>
    )
}

export default PopularSearchStatic
