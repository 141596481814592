import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,} from "react-bootstrap";
import "./ButtonGroupFooter.scss";


const StickyCTAFooter = (props) => {
  
  return (
    <React.Fragment>
     <section className="btn-group-footer prop-detail-sticky d-xl-none d-md-none">
        <div className="btn-group-footer-block">
          <Link to="/property-services/sell/property-valuation" className="btn btn-yellow">Valuation</Link>
          <Link to="/contact" className="btn btn-dark">Contact</Link>
        </div>
      </section>
    </React.Fragment>
  );
};

export default StickyCTAFooter;
